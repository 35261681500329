import { fromJS } from "immutable"

export const onModelProductsData = fromJS({
  "PANTS00155": {
    "black pearl": [
      {
        description: "Blue Fishtail Check",
        fullPrice: "$88",
        image: "https://bonobos-prod-s3.imgix.net/products/136829/original/Casual-Shirts_Summerweight-Shirts_23826-MVB96_20.jpg?1559160797=",
        name: "Lightweight Button-Down Shirt",
        onSale: false,
        price: "$88",
        product: {
          currency: "USD",
          fullPrice: "$88",
          isMarkDown: false,
          name: "Lightweight Button-Down Shirt",
          price: "$88",
          productId: "CSHRT00541",
          sku: "23827-MVB96-XLXSHOR",
          variant: {
            color: "blue fishtail check",
          },
        },
        sku: "CSHRT00541",
        slug: "lightweight-button-down-shirt",
      },
    ],
    "tahoe blue": [
      {
        description: "Navy Sardine Scatter",
        fullPrice: "$88",
        image: "https://bonobos-prod-s3.imgix.net/products/138458/original/Casual-Shirts_Summerweight-Shirts_24735-MVC06_20.jpg?1560880929=",
        name: "Lightweight Button-Down Shirt",
        onSale: false,
        price: "$88",
        product: {
          currency: "USD",
          fullPrice: "$88",
          isMarkDown: false,
          name: "Lightweight Button-Down Shirt",
          price: "$88",
          productId: "CSHRT00541",
          sku: "24735-MVC06-LXLONG",
          variant: {
            color: "navy sardine scatter",
          },
        },
        sku: "CSHRT00541",
        slug: "lightweight-button-down-shirt",
      },
    ],
    "smoked paprika": [
      {
        description: "Pink Matador Plaid",
        fullPrice: "$88",
        image: "https://bonobos-prod-s3.imgix.net/products/130099/original/Casual-Shirts_Summerweight-Shirts_23826-MVB89_20.jpg?1557086341=",
        name: "Lightweight Button-Down Shirt",
        onSale: false,
        price: "$88",
        product: {
          currency: "USD",
          fullPrice: "$88",
          isMarkDown: false,
          name: "Lightweight Button-Down Shirt",
          price: "$88",
          productId: "CSHRT00541",
          sku: "23824-MVB89-LXSHOR",
          variant: {
            color: "pink matador plaid",
          },
        },
        sku: "CSHRT00541",
        slug: "lightweight-button-down-shirt",
      },
    ],
    "river rock": [
      {
        description: "Green Palm Tree City",
        fullPrice: "$88",
        image: "https://bonobos-prod-s3.imgix.net/products/130105/original/Casual-Shirts_Summerweight-Shirts_24735-MVB92_20.jpg?1557086358=",
        name: "Lightweight Button-Down Shirt",
        onSale: false,
        price: "$88",
        product: {
          currency: "USD",
          fullPrice: "$88",
          isMarkDown: false,
          name: "Lightweight Button-Down Shirt",
          price: "$88",
          productId: "CSHRT00541",
          sku: "24735-MVB92-SXLONG",
          variant: {
            color: "green palm tree city",
          },
        },
        sku: "CSHRT00541",
        slug: "lightweight-button-down-shirt",
      },
    ],
    "grey": [
      {
        description: "Heather Blue Plaid",
        fullPrice: "$88",
        image: "https://bonobos-prod-s3.imgix.net/products/130102/original/Casual-Shirts_Summerweight-Shirts_23826-MVB85_20.jpg?1557086350=",
        name: "Lightweight Button-Down Shirt",
        onSale: false,
        price: "$88",
        product: {
          currency: "USD",
          fullPrice: "$88",
          isMarkDown: false,
          name: "Lightweight Button-Down Shirt",
          price: "$88",
          productId: "CSHRT00541",
          sku: "23824-MVB85-XLXREG",
          variant: {
            color: "heather blue plaid",
          },
        },
        sku: "CSHRT00541",
        slug: "lightweight-button-down-shirt",
      },
    ],
  },
  "PANTS00124": {
    "blue macaws": [
      {
        description: "Lake Blue Seersucker",
        fullPrice: "$88",
        image: "https://bonobos-prod-s3.imgix.net/products/132947/original/Casual-Shirts_Summerweight-Shirts_23929-BND65_20.jpg?1557152550=",
        name: "Lightweight Button-Down Shirt",
        onSale: false,
        price: "$88",
        product: {
          currency: "USD",
          fullPrice: "$88",
          isMarkDown: false,
          name: "Lightweight Button-Down Shirt",
          price: "$88",
          productId: "CSHRT00541",
          sku: "23929-BND65-2XLXLONG",
          variant: {
            color: "lake blue seersucker",
          },
        },
        sku: "CSHRT00541",
        slug: "lightweight-button-down-shirt",
      },
    ],
    "light winds": [
      {
        description: "Blue Beach Chair Stripe",
        fullPrice: "$88",
        image: "https://bonobos-prod-s3.imgix.net/products/138443/original/Casual-Shirts_Summerweight-Shirts_23826-MVC09_20.jpg?1560880884=",
        name: "Lightweight Button-Down Shirt",
        onSale: false,
        price: "$88",
        product: {
          currency: "USD",
          fullPrice: "$88",
          isMarkDown: false,
          name: "Lightweight Button-Down Shirt",
          price: "$88",
          productId: "CSHRT00541",
          sku: "23824-MVC09-SXLONG",
          variant: {
            color: "blue beach chair stripe",
          },
        },
        sku: "CSHRT00541",
        slug: "lightweight-button-down-shirt",
      },
    ],
  },
  "PANTS00206": {
    "french navy": [
      {
        description: "Lilac Antonio Plaid",
        fullPrice: "$128",
        image: "https://bonobos-prod-s3.imgix.net/products/132106/original/Dress-Shirts_Premium-Dress-Shirts_23829-MVC39_40_category-outfitter.jpg?1557119135=",
        name: "Jetsetter Stretch Dress Shirt",
        onSale: false,
        price: "$128",
        product: {
          currency: "USD",
          fullPrice: "$128",
          isMarkDown: false,
          name: "Jetsetter Stretch Dress Shirt",
          price: "$128",
          productId: "DSHRT00147",
          sku: "23835-MVC39-16X32",
          variant: {
            color: "lilac antonio plaid",
          },
        },
        sku: "DSHRT00147",
        slug: "jetsetter-stretch-dress-shirt",
      },
      {
        description: "Brown",
        fullPrice: "$128",
        image: "https://bonobos-prod-s3.imgix.net/products/97468/original/Mens-Accessories_Belt_21548-BR536_40_category-outfitter.jpg?1541274725=",
        name: "Premium Leather Dress Belt",
        onSale: false,
        price: "$138",
        product: {
          currency: "USD",
          fullPrice: "$138",
          isMarkDown: false,
          name: "Premium Leather Dress Belt",
          price: "$138",
          productId: "ACCES00226",
          sku: "21548-BR536-30",
          variant: {
            color: "brown",
          },
        },
        sku: "ACCES00226",
        slug: "premium-leather-dress-belt",
      },
    ],
    "lake life": [
      {
        description: "Blue Linen Herringbone",
        fullPrice: "$98",
        image: "https://bonobos-prod-s3.imgix.net/products/130413/original/Casual-Shirts_Unbutton-Down-Shirts_23861-BND67_20.jpg?1557093908=",
        name: "Unbutton Down Shirt",
        onSale: false,
        price: "$98",
        product: {
          currency: "USD",
          fullPrice: "$98",
          isMarkDown: false,
          name: "Unbutton Down Shirt",
          price: "$98",
          productId: "CSHRT00469",
          sku: "24224-BND67-MXREG",
          variant: {
            color: "blue linen herringbone",
          },
        },
        sku: "CSHRT00469",
        slug: "unbutton-down-shirt",
      },
      {
        description: "Brown",
        fullPrice: "$128",
        image: "https://bonobos-prod-s3.imgix.net/products/97468/original/Mens-Accessories_Belt_21548-BR536_40_category-outfitter.jpg?1541274725=",
        name: "Premium Leather Dress Belt",
        onSale: false,
        price: "$138",
        product: {
          currency: "USD",
          fullPrice: "$138",
          isMarkDown: false,
          name: "Premium Leather Dress Belt",
          price: "$138",
          productId: "ACCES00226",
          sku: "21548-BR536-30",
          variant: {
            color: "brown",
          },
        },
        sku: "ACCES00226",
        slug: "premium-leather-dress-belt",
      },
    ],
    "high rise": [
      {
        description: "Navy Herringbone Cotton Linen",
        fullPrice: "$98",
        image: "https://bonobos-prod-s3.imgix.net/products/128254/original/Casual-Shirts_Unbutton-Down-Shirts_23265-BNC95_20.jpg?1555536489=",
        name: "Unbutton Down Shirt",
        onSale: false,
        price: "$98",
        product: {
          currency: "USD",
          fullPrice: "$98",
          isMarkDown: false,
          name: "Unbutton Down Shirt",
          price: "$98",
          productId: "CSHRT00469",
          sku: "23265-BNC95-SXREG",
          variant: {
            color: "navy herringbone cotton linen",
          },
        },
        sku: "CSHRT00469",
        slug: "unbutton-down-shirt",
      },
      {
        description: "Brown",
        fullPrice: "$128",
        image: "https://bonobos-prod-s3.imgix.net/products/97468/original/Mens-Accessories_Belt_21548-BR536_40_category-outfitter.jpg?1541274725=",
        name: "Premium Leather Dress Belt",
        onSale: false,
        price: "$138",
        product: {
          currency: "USD",
          fullPrice: "$138",
          isMarkDown: false,
          name: "Premium Leather Dress Belt",
          price: "$138",
          productId: "ACCES00226",
          sku: "21548-BR536-30",
          variant: {
            color: "brown",
          },
        },
        sku: "ACCES00226",
        slug: "premium-leather-dress-belt",
      },
    ],
  },
})
